export default {
  control: {
    color: '#A8A6A6',
    fontFamily: 'Roboto-Light',
    backgroundColor: '#FFF',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0',
    outline: 'none',
    isFocused: {
      ':hover': {
        border: '1px solid #97D9F6',
      },
      boxShadow: 'none',
      outline: 'none',
      border: '1px solid #97D9F6',
      borderRadius: '0',
      backgroundColor: '#FFF',
      fontFamily: 'Roboto-Light',
    },
    isSelected: {
      border: 'none',
    },
  },
  singleValue: { color: '#000' },
  menu: { color: 'black' },
  option: {
    isSelected: {
      backgroundColor: '#97D9F6',
      color: 'black',
    },
    isFocused: {
      ':hover': { backgroundColor: '#B5E9FF' },
    },
  },
}

import React from 'react'

const ModalStateContext = React.createContext({})
const ModalDispatchContext = React.createContext({})

function modalReducer(state, action) {
  switch (action.type) {
    case 'hide':
      return null
    case 'show':
      return { component: action.payload, onClose: action.onClose }
    default:
      return state
  }
}

const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, null)
  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  )
}

const useModalState = () => React.useContext(ModalStateContext)
const useModalDispatch = () => React.useContext(ModalDispatchContext)

export { useModalState, useModalDispatch, ModalContextProvider }

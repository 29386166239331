import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

const useResponsive = (isMobileSsr = false) => {
  const isMobileMq = useMediaQuery({ query: '(max-width: 768px)' })
  const [mobile, setMobile] = useState(false)

  const isMobile = typeof window !== 'undefined' ? isMobileMq : isMobileSsr

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile, isMobileMq])

  return {
    isMobile: mobile,
  }
}

export default useResponsive

export default {
  menuBar: {
    height: '0.7rem',
    color: 'tmRed',
    cursor: 'pointer',
    borderRadius: '50%',
    border: '1px solid white',
    padding: '3px',
    transition: 'color 0.3s linear',
    ':hover': {
      color: 'tmRed',
    },
  },
  menuClose: {
    color: 'tmWhite',
    cursor: 'pointer',
    height: '2 rem',
    transition: 'color 0.3s linear',
    ':hover': {
      color: 'tmRed',
    },
  },
  menuCircle: {
    color: 'tmRed',
    width: '8px',
    paddingRight: '15px',
  },
  openArrow: {
    mb: '1vh',
    mr: '1.5vw',
    postion: 'absolute',
    zIndex: 2,
  },
  closeArrow: {
    mr: '1.5vw',
    transform: 'rotate(90deg)',
    postion: 'absolute',
    zIndex: 2,
  },
  localeArrow: {
    color: 'tmWhite',
  },
  carouselPlay: {
    color: 'tmWhite',
    filter: 'drop-shadow(0px 0px 20px black)',
    cursor: 'pointer',
    transition: '1s ease',
    ':hover': { color: 'tmRed', cursor: 'pointer' },
  },
  info: {
    width: '3vh',
    height: ['1vh', '0.5vh'],
    ml: '0.5vw',
    mt: '1vh',
  },
  infoToggle: {
    width: '3vh',
    height: '0.5vh',
    ml: '0.5vw',
  },
  nextArrow: {
    fontWeight: 100,
    p: 0,
    mt: '-0.5vh',
    width: ['40px', '50px', '100px'],
    height: ['40px', '50px', '100px'],
  },
  download: { color: 'accessories', fontSize: '24px', mr: '8px' },
}

export default {
  container: {
    backgroundColor: 'rgba(15, 32, 75, 0.9)',
    position: 'fixed',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '2.5rem',
    zIndex: 5,
    paddingTop: '47px',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease-in',
  },
  iconContainer: {
    display: ' flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '2.5rem',
    paddingLeft: '20px',
  },
  linksList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '2rem',
    listStyleType: 'none',
    padding: '0px',

    li: {
      backgroundColor: 'rgba(15, 32, 75, 0.7)',
      width: '100%',
      textTransform: 'uppercase',
      color: 'tmWhite',
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
  },
  menuOpened: {
    transform: 'translateX(0)',
    transition: 'transform 0.5s ease-in',
  },
}

import { saveAs } from 'file-saver'
import API from '/repository/repoAPI'
import logger from '/infra/logger'

const downloadImage = async (imageUrl, imageName) => {
  try {
    const { data } = await API.getCampAlbumImage()({ query: { imageUrl } })
    saveAs(data, imageName + '.jpg')
  } catch (error) {
    logger.error(`Failed to download image ${imageName}`, error)
  }
}

export default downloadImage

export default {
  carouselTextContainer: {
    top: ['37%', '30%'],
  },
  mainContentLinksBox: {
    div: {
      alignItems: 'center',
      gap: '10px',
      display: 'flex',
      flexDirection: 'column',
      h4: {
        px: ['0px', '25px'],
        fontSize: ['16px'],
        lineHeight: '20px',
      },
      button: { width: ['120px', '130px'] },
    },
  },
  playersTitleForVoteContainer: { mt: ['120px', '75px'] },
  playersListContainer: { mb: ['150px', '215px'] },
}

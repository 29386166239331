export default {
  title: {
    fontSize: '22px',
    fontFamily: 'bold',
    whiteSpace: 'no-wrap',
    lineHeight: '1.7',
  },
  subtitle: {
    fontSize: '20px',
    m: '10px 0',
    whiteSpace: 'pre-line',
    lineHeight: '1.2',
  },
  regular: {
    fontSize: '14px',
    fontFamily: 'regular',
    mt: '20px',
    whiteSpace: 'pre-line',
    lineHeight: '1.3',
  },
  playersTitle: {
    color: 'tmRed',
    fontSize: ['30px', '30px', '60px'],
    textAlign: 'center',
    fontFamily: 'AIA-Heading-Regular',
  },
  playersTitleSmall: {
    color: 'tmBlue',
    fontSize: ['20px', '20px', '28px'],
    textAlign: 'center',
    fontFamily: 'AIA-Heading-Regular',
  },
  playerName: {
    color: 'tmBlue',
    fontFamily: 'bold',
    fontSize: ['22px', '22px', '18px'],
    fontWeight: 900,
    mt: ['2vh', '2vh', '0px'],
    textTransform: 'uppercase',
  },
  formsTitle: {
    color: 'tmGold',
    fontFamily: 'heading',
    fontSize: [4, 4, 5],
    mb: '20px',
  },
  formsSubtitle: {
    color: 'tertiary',
    fontFamily: 'heading',
    fontSize: [4, 4, 5],
    mb: '20px',
  },
  stats: {
    title: {
      color: ['tmBlue', 'tmBlue', 'primary'],
      fontFamily: 'AIA-Body-Bold',
      fontSize: ['22px', '22px', '16px'],
    },
    value: {
      fontFamily: 'AIA-Body-Bold',
      fontSize: ['22px', '22px', '16px'],
      color: ['tmRed', 'tmRed', 'primary'],
      ml: ['6px', '10px', '0px'],
    },
  },
}

/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react'
import tracking from '/lib/tracking'
import { jsx, Box } from 'theme-ui'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faHome, faCircle } from '@fortawesome/free-solid-svg-icons'
import {
  TRACKING_EVENT_NAME,
  TRACKING_ACTION,
  TRACKING_CATEGORY,
} from '/constants/siteTagging'
import Utils from '/utils'
import styles from './styles'
import redirectToPage from '/lib/redirect'

const ListMenu = ({ onClose, opened = false, navItems }) => {
  const router = useRouter()
  const locale = router?.query?.locale
  const { sendCSMEvent, sendAdobeEvent } = tracking()

  useEffect(() => {
    if (opened) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [opened])

  return (
    <Box sx={{ ...styles.container, ...(opened && styles.menuOpened) }}>
      <Box sx={styles.iconContainer}>
        <FontAwesomeIcon
          icon={faClose}
          sx={{
            variant: 'icons.menuClose',
          }}
          size="2x"
          onClick={onClose}
        />
      </Box>
      <ul sx={styles.linksList}>
        {navItems
          ?.filter((i) => i.text !== '' && i.text !== undefined)
          ?.map((element, index) => (
            <li
              key={index}
              onClick={() => {
                const trackInfo = Utils.getTrackingInfo(element.link, true)
                sendCSMEvent(
                  trackInfo.eventName,
                  TRACKING_ACTION.clicked_cta,
                  TRACKING_CATEGORY.menu_bar,
                  trackInfo.eventAttribute,
                  locale,
                )
                sendAdobeEvent(TRACKING_EVENT_NAME.navigation_click, {
                  navigationLinkNameClicked: trackInfo.eventAttribute,
                  navigationLocation: `${locale}${element.link}`,
                  language: locale,
                  websiteCountry: locale,
                })
                redirectToPage(router, locale, element.link)
                onClose()
              }}
            >
              {element.link === '/' ? (
                <FontAwesomeIcon
                  icon={faHome}
                  sx={{ variant: 'icons.menuClose' }}
                  size="1x"
                />
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon
                    icon={faCircle}
                    sx={{ variant: 'icons.menuCircle' }}
                  />
                  {element.text}
                </React.Fragment>
              )}
            </li>
          ))}
      </ul>
    </Box>
  )
}

export default ListMenu

import React from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

const Auth = ({ children }) => {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  const router = useRouter()
  if (!loading && !session) {
    router.push('/kr/login')
  }
  if (loading || !session) {
    return <div> Waiting for session...</div>
  }
  return children
}

export default Auth

export default {
  primary: '#fff',
  secondary: '#000',
  tmWhite: '#FFF',
  tmGrey: '#A2A8B9',
  tmRed: '#D31345',
  tmBlue: '#0F204B',
  tmBlueV3: '#001B3F',
  tmLightBlue: 'rgba(26, 43, 84, 0.9)',
  titleRed: '#D31445',
  tmYellow: '#ffe602',
  tmGold: '#b99a58',
  sideNavRed: '#E70041',
  tmDarkBlue: '#001C42',
}

export default {
  light: 'AIAEverest-Regular',
  bold: 'AIAEverest-Bold',
  regular: 'AIAEverest-Regular',
  heading: 'AIAEverest-Condensed',
  spoqaRegular: 'SpoqaHanSansNeo-Regular',
  spoqaBold: 'SpoqaHanSansNeo-Bold',
  spoqaLight: 'SpoqaHanSansNeo-Light',
  spoqaThin: 'SpoqaHanSansNeo-Thin',
  spoqaMedium: 'SpoqaHanSansNeo-Medium',
}

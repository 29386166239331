export default {
  headerButton: {
    fontSize: '20px',
  },
  voteButton: {
    fontSize: '20px',
  },
  viewStats: {
    fontSize: '12px',
    div: {
      pt: '2px',
    },
  },
}

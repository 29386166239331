export default {
  contentLinksBox: {
    backgroundColor: 'transparent',
    height: ['211px', '220px'],
    position: 'relative',
    cursor: 'pointer',
    ':hover': {
      'div:first-of-type': { backgroundColor: 'tmRed' },
      div: {
        button: { color: 'tmWhite', backgroundColor: 'tmBlue' },
      },
    },
    div: {
      transition: '0.5s ease',
      alignItems: 'stretch',
      gap: '10px',
      h4: {
        color: 'tmWhite',
        zIndex: 1,
      },
      button: { zIndex: 1, width: 'auto' },
    },
    'img:not(.icon)': {
      borderRadius: '10px 10px 10px 0px',
      position: 'absolute',
      left: 0,
      top: 0,
      maxWidth: '100%',
      height: '100%',
      zIndex: -1,
      filter: 'grayscale(88%)',
      opacity: 0.8,
      objectFit: 'cover',
    },
  },
  mainContentLinksBox: {
    backgroundColor: 'tmRed',
    width: ['100%', '180px'],
    height: ['auto', '220px'],
    display: 'flex',
    flexDirection: ['row', 'column'],
    alignItems: 'center',
    justifyContent: ['center', 'flex-end'],
    padding: '0px',
    gap: ['20px', '15px'],
    cursor: 'pointer',
    transition: '0.5s ease',
    ':hover': {
      backgroundColor: 'tmBlue',
      div: {
        button: { color: 'tmWhite', backgroundColor: 'tmRed' },
      },
    },
    div: {
      alignItems: 'stretch',
      gap: '10px',
      h4: {
        color: 'tmWhite',
        textAlign: 'left',
      },
      button: { zIndex: 1, width: 'auto', color: 'black' },
    },
    img: {
      paddingTop: ['10px', '0px'],
      width: ['120px', '90px'],
      order: [0, 1],
    },
  },
  spinnerContainer: {
    width: '100vw',
    height: ['630px', '600px', '570px'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: { color: 'tmBlue' },
  },
  playersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    pb: ['50px', '50px', 'auto'],
  },
  playersTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '90px',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: ['80vw', '60vw', '60vw'],
  },
  playersListContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    flexWrap: ['nowrap', 'nowrap', 'wrap'],
    justifyContent: ['space-between', 'space-between', 'center'],
    mt: ['45px', '5vh'],
    px: '3vw',
    mb: '120px',
    gap: ['45px', '20px'],
  },
  playersTitleForVoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: ['120px', '75px'],
    pb: '0px',
  },
  statsMobile: {
    display: ['flex', 'flex', 'none'],
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90vw',
    maxWidth: '600px',
  },
}

import axios from 'axios'
import getConfig from 'next/config'
import removeEmpty from '/utils/removeEmpty'

import Logger from './logger'

const { publicRuntimeConfig } = getConfig()

const APIConfig = publicRuntimeConfig.APIConfig

const API =
  (apiName = '') =>
  (domain) => {
    const findConfigByName = (config) => config.name === apiName

    const service = APIConfig.find(findConfigByName)

    if (!service) {
      throw new Error(`This ${apiName} does not exist on the API configuration`)
    }

    const domainEndpoint = {
      UI: '/api',
      default: service.baseUrl + service.endpoint,
    }

    const endpoint = domainEndpoint[domain] || domainEndpoint['default']
    const get = (path, params = {}) => {
      Logger.info(`${path}, params: ${JSON.stringify(params)}, method: 'GET`)
      const options = {
        ...params,
        headers: removeEmpty({
          ...params.headers,
        }),
        params: params.query || params.params || {},
      }
      return axios.get(endpoint + path, options).catch((error) => {
        Logger.error(`Unable to GET: ${path}`)
        throw error
      })
    }

    const post = (path, params = {}) => {
      const { headers = {}, body, query = {} } = params
      Logger.info(`${path}, params: ${JSON.stringify(params)}, method: 'POST`)

      const options = {
        headers: removeEmpty({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        }),
        params: query || params.params || {},
      }

      return axios.post(endpoint + path, body, options).catch((error) => {
        Logger.error(`Unable to POST: ${path}, Body: ${JSON.stringify(body)}`)
        throw error
      })
    }

    return {
      get,
      post,
    }
  }
export default API

export default {
  borderRadius: '10px',
  border: '0.1rem solid',
  borderColor: 'tmGold',
  color: 'tmBlue',
  fontSize: ['90%', '100%'],
  gridColumnStart: ['1', 'auto'],
  gridColumnEnd: ['5', 'auto'],
  width: '100%',
  '&:focus': {
    outlineColor: 'transparent',
    outlineStyle: 'none',
  },
  '&:placeholder': {
    color: 'tmBlue',
    fontFamily: 'light',
    bg: 'transparent',
  },
  '&:WebkitAutofill:active': {
    bg: 'primary',
    background: 'primary',
    color: 'tmBlue',
  },
  ':WebkitAutofill': {
    color: 'tmBlue',
    background: 'primary',
    boxShadow: 'inset 0 0 0 1000px primary !important',
  },
  ':WebkitAutofill:hover': {
    color: 'tmBlue',
    background: 'primary',
    boxShadow: 'inset 0 0 0 1000px primary',
  },
  ':WebkitAutofill:focus': {
    color: 'tmBlue',
    background: 'primary',
    boxShadow: 'inset 0 0 0 1000px primary',
  },
  '@media screen and (max-width: 800px)': {
    mr: 0,
  },
}

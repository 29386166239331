export default {
  inputContainer: {
    fontFamily: 'light',
    mb: '10px',
    p: '1px',
    input: {
      fontFamily: 'light',
      py: '12px',
      '--theme-ui-input-autofill-bg': 'white',
    },
    gridColumnStart: '1',
    gridColumnEnd: '3',
    placeholder: { color: 'tmBlue', fontFamily: 'light' },
    label: { py: '7px' },
    '[class*="error-message"]': {
      textAlign: 'left',
    },
  },
  textareaContainer: {
    fontFamily: 'light',
    mb: '10px',
    p: '1px',
    textarea: {
      fontFamily: 'light',
      py: '12px',
      '--theme-ui-input-autofill-bg': 'white',
    },
    gridColumnStart: '1',
    gridColumnEnd: '3',
    placeholder: { color: 'tmBlue', fontFamily: 'light' },
    label: { py: '7px' },
    '[id*="error_message"]': {
      textAlign: 'left',
    },
  },
  countryContainer: {
    p: '1px',
    mb: '10px',
    gridColumnStart: '1',
    gridColumnEnd: '3',
    color: 'tmBlue',
    textAlign: 'left',
    fontFamily: 'light',
    fontSize: ['90%', '100%'],
    '&:focus': {
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    '&:placeholder': { color: 'tmBlue', fontFamily: 'light' },
    div: {
      border: '0px',
      outline: '0px',
    },
    '>div': {
      borderRadius: '10px',
      borderColor: 'tmGold',
      borderStyle: 'solid',
      borderWidth: '0.1rem',
    },
    'div[class*="control"]': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',

      div: {
        overflow: 'visible',
        boxShadow: 'auto',
      },
      'span[class*="indicatorSeparator"]': { display: 'none' },
      'div[class*="indicatorContainer"]': {
        color: 'tmGold',
      },
    },
    'div[class*="menu"]': {
      textAlign: 'left',
    },
    label: { py: '7px' },
  },
  selectContainer: {
    mb: '10px',
    gridColumnStart: '1',
    gridColumnEnd: '3',

    textAlign: 'left',
    color: 'tmBlue',
    fontFamily: 'light',
    fontSize: ['90%', '100%'],
    width: '100%',
    label: { py: '7px' },
    '&:focus': {
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    '&:placeholder': { color: 'tmBlue', fontFamily: 'light' },
    div: {
      border: '0px',
      outline: '0px',
    },
    '>div': {
      borderRadius: '10px',
      borderColor: 'tmGold',
      borderStyle: 'solid',
      borderWidth: '0.1rem',
    },
    'div[class*="control"]': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'pointer',

      div: {
        overflow: 'visible',
        boxShadow: 'auto',
      },
      'span[class*="indicatorSeparator"]': { display: 'none' },
      'div[class*="indicatorContainer"]': {
        color: 'tmGold',
      },
    },
    'div[class*="menu"]': {
      textAlign: 'left',
    },

    value: { color: 'red' },
  },
  phoneContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
  },
  radioContainer: {
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-around',
    gridColumnStart: ['1', 'auto'],
    gridColumnEnd: ['5', 'auto'],
  },
  checkboxContainer: {
    '[class*="error-message"]': {
      textAlign: 'left',
      ml: '32px',
    },
    fontFamily: 'light',
    svg: {
      path: {
        fill: 'tmGold',
      },
    },
    '::focus': {
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    a: { color: 'tmGold', fontWeight: 900 },
    gridColumnStart: '1',
    gridColumnEnd: '5',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-around',
    mt: '2vh',
    mb: '1vh',
    pr: '3vw',
    div: {
      label: {
        div: {
          display: 'flex',
          alignItems: 'center',
          p: {
            fontFamily: 'light',
            color: 'secondary',
            fontSize: '12px',
            textAlign: 'left',
          },
        },
      },
    },
  },
  dateContainer: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    label: { color: '#7b7b7b', fontFamily: 'light', mb: '3px' },
    div: {
      display: 'flex',
      gap: '10px',
    },
    select: {
      color: '#262626',
      fontFamily: 'light',
      bg: '#FFFFFF',
      borderStyle: 'solid',
      borderColor: 'tmGold',
      borderWidth: '1px',
      borderRadius: '7px',
      padding: '5px',
      fontSize: '14px',
      lineHeight: '14px',
      outline: 'none',
      mt: '0px',
      mb: '10px',
      mr: '0px',
      ml: '0px',
    },
  },
  multipleCheckboxesContainer: {
    fontFamily: 'light',
    pt: '10px',
    fieldset: {
      textAlign: 'left',
      legend: { fontSize: ['90%', '100%'], fontFamily: 'light' },
    },
  },
  multipleCheckboxes: {
    mb: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '3px',
    checksContainer: {
      label: {
        fontSize: ['90%', '100%'],
        fontFamily: 'light',
        alignItems: 'center',
        div: {
          p: {
            my: '10px',
          },
          svg: {
            path: { fill: 'tmGold' },
          },
        },
      },
    },
  },
  submitContainer: {
    mb: '100px',
    gridColumnStart: '1',
    gridColumnEnd: '3',
    display: 'flex',
    justifyContent: 'center',
    button: {
      color: 'primary',
      bg: 'tmGold',
      display: 'flex',
      borderRadius: '7px',
      height: '2.5rem',
      fontSize: [2, 3, 3],
      fontFamily: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      width: ['100%', '15vw'],
      gridColumnStart: '1',
      gridColumnEnd: '2',
      my: '40px',
      '&:hover': {
        background: 'transparent',
        borderColor: 'tmGold',
        border: 'solid',
        color: 'tmGold',
        width: ['100%', '15vw'],
      },
    },
  },
  markdown: {
    fontFamily: 'light',
    fontSize: '12px',
    color: 'secondary',
  },
}

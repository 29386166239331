export default {
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  logoContainer: {
    width: '30%',
    position: 'absolute',
    top: '5%',
    margin: '0 auto',
  },
  shapeBox: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: '40px',
    filter: 'drop-shadow(0px -5px 20px white)',
    zIndex: '1',
  },
}

import requestIp from 'request-ip'
import API from '/repository/repoAPI'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

function getPublicIP(req) {
  return process.env.NODE_ENV == 'development'
    ? 'localhost'
    : requestIp.getClientIp(req) || '0.0.0.0'
}

const getLocaleFromIp = (geoIp) => {
  return publicRuntimeConfig.ISOCODEMAPConfig[geoIp?.isoCode]
    ? publicRuntimeConfig.ISOCODEMAPConfig[geoIp?.isoCode]
    : publicRuntimeConfig.ISOCODEMAPConfig.GB
}

const getGeoIpData = async (req) => {
  const ip = getPublicIP(req)
  const response = await API.getGeoIP('')({
    query: { ip: encodeURIComponent(ip) },
  })
  const geoip = response.data
  const locale = getLocaleFromIp(geoip)

  return {
    ip,
    locale,
    isoCode: geoip.isoCode || null,
  }
}

export { getPublicIP, getGeoIpData }

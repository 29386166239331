import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SessionProvider } from 'next-auth/react'

import 'setimmediate'
import { ThemeProvider, merge } from 'theme-ui'
import { theme } from '/styles'

import GlobalComponent from '/styles/global'
import fontsKh from '/styles/fontsKh'
import fontsTh from '/styles/fontsTh'
import fontsVn from '/styles/fontsVn'
import textTh from '/styles/textTh'
import textKh from '/styles/textKh'
import buttonsKh from '/styles/buttonsKh'
import textVn from '/styles/textVn'
import buttonsVn from '/styles/buttonsVn'
import fontsMm from '/styles/fontsMm'
import textMm from '/styles/textMm'
import buttonsMm from '/styles/buttonsMm'
import boxMm from '/styles/boxMm'
import textHk from '/styles/textHk'
import boxHk from '/styles/boxHk'
import buttonsHk from '/styles/buttonsHk'
import boxTh from '/styles/boxTh'
import boxId from '/styles/boxId'
import boxKh from '/styles/boxKh'

import initServiceWorker from '/mocks'
import Utils from '/utils'
import { AppProviders } from '/contexts'

import { AdobeLaunch } from '/components'
import fontsKr from '/styles/fontsKr'
import Auth from '/components/Auth'

initServiceWorker()

if (!global.setImmediate) {
  global.setImmediate = setTimeout
}

function GreenroomApp({ Component, ...props }) {
  const router = useRouter()
  const { meta = {}, layout = {} } = props.pageProps
  const title = `${meta?.title} | ${layout && layout?.title}`
  const query = {
    locale: Utils.parseUrl(router.asPath).locale,
  }
  const locale = Utils.locale.getLocaleFromQuery(query)

  // Declare Adobe Launch DigitalData
  if (typeof window !== 'undefined') {
    window.digitalData = {}
  }
  const digitalData = {
    pageName: title,
    pageCategory: layout?.title,
    pageSubCategory: layout?.title,
    language: locale,
    websiteCountry: locale,
    pageUrl: router.asPath,
    // pageUrl: window.location.href,
  }

  if (typeof window !== 'undefined') {
    window.digitalData = Object.assign(window.digitalData, digitalData)
  }

  const finalTheme = (locale) => {
    if (locale === 'kh') {
      return merge(theme, {
        fonts: fontsKh,
        text: textKh,
        buttons: buttonsKh,
        box: boxKh,
      })
    } else if (locale === 'id') {
      return merge(theme, { box: boxId })
    } else if (locale === 'th') {
      return merge(theme, { fonts: fontsTh, text: textTh, box: boxTh })
    } else if (locale === 'vn') {
      return merge(theme, { fonts: fontsVn, text: textVn, buttons: buttonsVn })
    } else if (locale === 'zh-HK') {
      return merge(theme, { text: textHk, box: boxHk, buttons: buttonsHk })
    } else if (locale === 'kr') {
      return merge(theme, { fonts: fontsKr })
    } else if (locale === 'mm') {
      return merge(theme, {
        fonts: fontsMm,
        text: textMm,
        buttons: buttonsMm,
        box: boxMm,
      })
    } else {
      return theme
    }
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <link
          rel="preload"
          href="/static/assets/fonts/AIA-Body-Bold.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/AIA-Body-Regular.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/AIA-Heading-Regular.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/AIAEverest-Bold.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/AIAEverest-Condensed.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/AIAEverest-Regular.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/SpoqaHanSansNeo-Bold.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/SpoqaHanSansNeo-Regular.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/SpoqaHanSansNeo-Thin.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/SpoqaHanSansNeo-Light.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/static/assets/fonts/SpoqaHanSansNeo-Medium.woff"
          as="font"
          crossOrigin=""
          type="font/woff2"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>

      <SessionProvider session={props.pageProps.session} isNotProtected={true}>
        <GlobalComponent />
        {Component.isProtected ? (
          <Auth>
            <AppProviders>
              <ThemeProvider theme={finalTheme(locale)}>
                <GlobalComponent />
                <Component {...props} />
              </ThemeProvider>
            </AppProviders>
          </Auth>
        ) : (
          <AppProviders>
            <ThemeProvider theme={finalTheme(locale)}>
              <GlobalComponent />
              <Component {...props} />
            </ThemeProvider>
          </AppProviders>
        )}
      </SessionProvider>
      {meta?.adobeLaunchCode && <AdobeLaunch tag={meta?.adobeLaunchCode} />}
    </>
  )
}
export default GreenroomApp

export default {
  headerTitle: {
    fontSize: ['1.3rem', '2.2rem'],
    marginBottom: '15px',
  },
  headerSubtitle: {
    fontSize: ['1.5rem', '2.7rem'],
  },
  headerTopTitle: {
    marginBottom: ['25px', '30px'],
  },
  localeTitle: {
    fontSize: '1.3rem',
  },
  playersTitle: {
    fontSize: ['20px', '40px'],
    fontFamily: 'bold',
  },
  playersTitleSmall: {
    fontSize: ['14px', '20px'],
    color: 'primary',
    fontFamily: 'bold',
  },
  stats: {
    title: {
      color: 'primary',
      fontFamily: 'bold',
      fontSize: ['16px', '12px'],
    },
    value: {
      fontFamily: 'bold',
      fontSize: ['16px', '12px'],
      color: ['tmRed', 'tmRed', 'primary'],
      ml: ['6px', '10px', '0px'],
    },
  },
}

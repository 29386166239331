export default {
  headerTitle: {
    fontSize: ['1.3rem', '3rem'],
    marginBottom: '15px',
  },
  headerSubtitle: {
    fontSize: ['1.5rem', '4rem'],
  },
  headerTopTitle: {
    fontSize: ['2rem', '3.5rem'],
    marginBottom: '15px',
  },
  headerBottomTitle: {
    fontSize: ['4.5rem', '5.3rem'],
  },
  contentTitle: {
    fontSize: ['1.7rem', '2rem'],
    lineHeight: '30px',
  },
  partnershipDescription: {
    fontSize: ['0.9rem', '1rem'],
    py: ['20px', '30px'],
  },
  localeTitle: {
    fontSize: '1.5rem',
  },
}

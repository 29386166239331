export default requester

async function requester(prms) {
  const results = await Promise.allSettled(prms)

  const errors = results
    .filter((p) => p.status === 'rejected')
    .map((p) => p.reason)

  return [results, errors]
}

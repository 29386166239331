export default {
  navMobilecontainer: {
    position: 'fixed',
    left: '0px',
    top: '40px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    backgroundColor: 'rgba(15, 32, 75, 0.8)',
    padding: '5px 10px 5px 30px',
    borderBottomRightRadius: '10px',
    cursor: 'pointer',
    zIndex: 2,
  },
  linktitle: {
    fontFamily: 'bold',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
}

export default {
  mainContentLinksBox: {
    div: {
      alignItems: 'center',
      gap: '10px',
      display: 'flex',
      flexDirection: 'column',
      h4: {
        px: ['0px', '25px'],
        fontSize: ['18px', '20px'],
        lineHeight: '28px',
      },
      button: { width: ['120px', '130px'] },
    },
  },
  contentLinksBox: {
    div: {
      h4: { fontSize: ['18px', '20px'], lineHeight: '30px' },
    },
  },
  playersTitleForVoteContainer: {
    mt: ['120px', '85px'],
  },
}

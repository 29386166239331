export default {
  headerButton: {
    fontSize: '16px',
  },
  partnershipButton: {
    fontSize: '16px',
  },
  viewStats: {
    fontSize: '10px',
    py: '18px',
  },
  voteButton: { fontSize: '20px' },
}

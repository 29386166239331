/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react'
import { useRouter } from 'next/router'
import { jsx, Box } from 'theme-ui'
import {
  TRACKING_EVENT_NAME,
  TRACKING_ACTION,
  TRACKING_CATEGORY,
  TRACKING_ATTRIBUTE,
} from '/constants/siteTagging'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import ListMenu from '/components/Commons/ListMenu'
import styles from './styles'
import tracking from '/lib/tracking'

export const NavMobile = ({ navItems }) => {
  const [openList, setOpenList] = useState(false)
  const { sendCSMEvent } = tracking()
  const router = useRouter()
  const locale = router?.query?.locale

  const getTrackingInfo = () => {
    const isFOMRoute = router.route === '/[locale]/partnership'

    let eventCategory = isFOMRoute
      ? TRACKING_CATEGORY.fom_page
      : TRACKING_CATEGORY.homepage

    let eventAttribute = isFOMRoute
      ? TRACKING_ATTRIBUTE.aia_one_billion
      : TRACKING_ATTRIBUTE.menu_bar

    return { eventCategory, eventAttribute }
  }

  const onClickMenu = (e) => {
    e.stopPropagation()
    setOpenList(true)

    const trackInfo = getTrackingInfo()
    sendCSMEvent(
      TRACKING_EVENT_NAME.menu_bar,
      TRACKING_ACTION.clicked_cta,
      trackInfo.eventCategory,
      trackInfo.eventAttribute,
      locale,
    )
  }

  return (
    <nav>
      <Box sx={styles.navMobilecontainer} onClick={onClickMenu}>
        <span sx={{ ...styles.linktitle, variant: `text.menuLinktitle` }}>
          {navItems?.title}
        </span>
        <FontAwesomeIcon
          icon={faBars}
          sx={{
            variant: 'icons.menuBar',
          }}
        />
      </Box>
      <ListMenu
        onClose={() => setOpenList(!openList)}
        opened={openList}
        navItems={navItems?.menu?.links}
      />
    </nav>
  )
}

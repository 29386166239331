/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { Button as ButtonUI, jsx } from 'theme-ui'
import defaultStyles from './styles'

const DotButton = React.forwardRef(
  ({ disabled, buttonStyle, styles, ...props }, ref) => (
    <ButtonUI
      ref={ref}
      {...props}
      sx={{
        ...defaultStyles,
        ...(disabled && { backgroundColor: 'tmBlue' }),
        variant: `buttons.${buttonStyle}`,
        ...styles,
      }}
    />
  ),
)
export default DotButton

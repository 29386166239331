import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const whiteListLocale = publicRuntimeConfig.LOCALESConfig
const defaultLocale = whiteListLocale[0]

const isAValidLocale = (locale) => whiteListLocale.includes(locale)

function getValidLocale(locale) {
  return isAValidLocale(locale) ? locale : defaultLocale
}

function getLocaleFromQuery(query) {
  const locale = getValidLocale(query.locale)
  return Array.isArray(locale) ? locale[0] : locale
}

const throwIfInvalidLocale = (locale) => {
  if (!isAValidLocale(locale)) {
    throw new Error(`Locale ${locale} is not valid`)
  }
}

export default {
  whiteListLocale,
  defaultLocale,
  isAValidLocale,
  getValidLocale,
  getLocaleFromQuery,
  throwIfInvalidLocale,
}

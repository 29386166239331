export default {
  mainContentLinksBox: {
    div: {
      alignItems: 'center',
      gap: '10px',
      display: 'flex',
      flexDirection: 'column',
      h4: {
        px: ['0px', '25px'],
        fontSize: '2.1rem',
      },
      button: { width: ['100px', '130px'] },
    },
  },
  statsMobile: {
    display: ['flex', 'flex', 'none'],
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95vw',
    maxWidth: '600px',
  },
  playersTitleForVoteContainer: {
    mt: ['120px', '90px'],
  },
}

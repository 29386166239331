import { keyframes } from '@emotion/react'

const growthWidth = keyframes({
  from: {
    width: '0%',
  },
  to: {
    width: '100%',
  },
})

export default {
  container: {
    width: '100%',
    left: '0px',
    top: '0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    backgroundColor: 'tmBlue',
    padding: '10px',
    zIndex: 3,
  },
  logoContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'bold',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },

  linksList: {
    width: '100%',
    display: 'flex',
    color: 'tmWhite',
    listStyleType: 'none',
    gap: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    paddingTop: '10px',
    margin: 0,

    li: {
      cursor: 'pointer',
      transition: '1s ease',
      fontSize: '18px',
      fontWeight: '700',
      ':hover': {
        color: 'tmRed',
      },
    },
  },
  bar: {
    display: 'flex',
    height: '3px',
    backgroundColor: '#D31345',
    width: '0px',
  },
  active: {
    animation: `${growthWidth} 1s backwards`,
    width: '100%',
  },
}

export default {
  headerTitle: {
    fontSize: ['1.3rem', '2.2rem'],
    marginBottom: '15px',
  },
  headerSubtitle: {
    fontSize: ['1.5rem', '2.7rem'],
  },
  headerTopTitle: {
    fontSize: ['1.5rem', '2rem'],
  },
  headerBottomTitle: {
    fontSize: ['1.7rem', '2.5rem'],
    ':after': {
      content: '"."',
      color: 'tmRed',
      width: '10px',
      fontSize: '3rem',
      paddingLeft: '5px',
    },
  },
  localeTitle: {
    fontSize: '1rem',
  },

  navBarlinksList: {
    li: {
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  contentTitle: {
    fontSize: '1.2rem',
  },
  menuLinktitle: {
    fontSize: '1.2rem',
  },
  challengeTitle: {
    fontSize: ['1.3rem', '1.7rem'],
  },
  partnershipTitle: {
    fontSize: ['1rem', '1.3rem'],
  },
  partnershipDescription: {
    fontSize: ['0.9rem', '1rem'],
    py: ['20px', '30px'],
  },
}

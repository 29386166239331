export default {
  primary: {
    color: 'primary',
    bg: 'lightBlue',
    gridColumnStart: '1',
    gridColumnEnd: '3',
    justifySelf: 'center',
    fontFamily: 'bold',
    fontSize: '18px',
    mt: '20px',
    p: '15px 30px',
    borderRadius: '0px',
    '&:hover': {
      bg: '#bd2042',
    },
    '&:disabled': {
      bg: 'gray',
      opacity: '0.5',
    },
  },
  partnershipButton: {
    color: 'tmWhite',
    fontWeight: 'bold',
    fontSize: '20px',
    backgroundColor: 'tmRed',
    borderRadius: '5px 5px 5px 0px',
    padding: '7px 30px',
    margin: ['10px auto', '15px auto'],
    display: 'block',
    letterSpacing: '1px',
    cursor: 'pointer',
    transition: '0.5 ease',

    ':hover': {
      backgroundColor: 'tmBlue',
    },
  },
  blue: {
    backgroundColor: 'tmBlue',
    color: 'tmWhite',
    cursor: 'pointer',
    transition: '0.5 ease',
    fontSize: '18px',
    textTransform: 'uppercase',
    borderRadius: '10px 10px 10px 10px',
    padding: '10px 12px 8px 12px',
    fontFamily: 'AIAEverest-Condensed',
    fontWeight: 'bold',
    ':hover': {
      backgroundColor: 'tmRed',
    },
  },
  simple: {
    backgroundColor: 'transparent',
    color: 'tmWhite',
  },
  voteButton: {
    color: 'primary',
    bg: 'tmBlue',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    fontFamily: 'AIA-Body-Bold',
    fontSize: '30px',
    width: '156px',
    height: '40px',
    mt: '10px',
    borderRadius: '7px',
    '&:hover': {
      bg: 'tmRed',
    },
    '&:disabled': {
      bg: 'gray',
      opacity: '0.5',
    },
  },
  viewStats: {
    color: 'primary',
    textTransform: 'uppercase',
    bg: 'tmBlue',
    fontFamily: 'AIA-Body-Bold',
    fontSize: '16px',
    height: '10px',
    py: '10px',
    px: '0px',
    mt: '10px',
    width: '156px',
    borderRadius: '4px',
    '&:hover': {
      bg: 'tmRed',
    },
    '&:disabled': {
      bg: 'gray',
      opacity: '0.5',
    },
    zIndex: '1',
    boxShadow: '1px 1px 20px -1px rgb(0, 0, 0, 0.50)',
    webkitBoxShadow: '1px 1px 20px -1px rgb(0, 0, 0, 0.50)',
    mozBoxShadow: '1px 1px 20px -1px rgb(0, 0, 0, 0.50)',
    cursor: 'pointer',
  },
}

import _ from 'lodash'

const addRegionsDependentQuestion = (
  locale,
  form,
  countryAndRegionsData,
  questionNamePrefix = '',
) => {
  const countryQuestion = form?.questions?.find((q) => q.type === 'country')

  if (!_.isNil(countryQuestion)) {
    countryQuestion.dependentQuestions =
      countryQuestion.dependentQuestions?.length > 0
        ? countryQuestion.dependentQuestions
        : []

    countryAndRegionsData?.forEach((country) => {
      const placeholder = {
        city: {
          id: 'Lota*',
          th: 'เมือง*',
          kh: 'ទីក្រុង*',
          ph: 'City*',
          vn: 'Thành phố*',
          mm: 'မြို*',
        },
        region: {
          id: 'Wilayah*',
          th: 'ภูมิภาค*',
          kh: 'តំបន់*',
          ph: 'Province*',
          vn: 'Khu vực*',
          mm: 'တိုင်းဒေသကြီး/ပြည်နယ်*',
        },
      }

      if (country?.regions) {
        // Regions with cities are a Province -> City scenario so we prepare their dependent questions
        const cityDependentQuestions = []

        if (country.regions[0]?.cities) {
          country.regions.forEach((region) => {
            const question = {
              condition: region.cn.toLowerCase().replace(/ /g, '_'),
              question: {
                name: `${questionNamePrefix}city`,
                type: 'select',
                registerConfig: {
                  required: true,
                },
                errorMessages: countryQuestion.errorMessages,
                placeholder: placeholder.city[locale] || 'City*',
                config: {
                  options: region.cities?.map((city) => ({
                    label: city.cn,
                    value: city.cn.toLowerCase().replace(/ /g, '_'),
                  })),
                },
              },
            }

            cityDependentQuestions.push(question)
          })
        }

        // Add the Region (or Province) dependent question
        countryQuestion.dependentQuestions.unshift({
          condition: country.cs,
          label: country.cn,
          question: {
            type: 'select',
            name:
              country.cs === 'PH' ? `${questionNamePrefix}province` : 'region',
            id: '',
            placeholder: placeholder.region[locale] || 'Region*',
            registerConfig: {
              required: true,
            },
            errorMessages: countryQuestion.errorMessages,
            config: {
              options: country.regions?.map((region) => ({
                label: region.cn,
                value: region.cn.toLowerCase().replace(/ /g, '_'),
              })),
            },
            dependentQuestions: cityDependentQuestions,
          },
        })
      }
    })
  }

  return form
}

export default addRegionsDependentQuestion

export default {
  headerTopTitle: {
    fontSize: ['2.5rem', '2.7rem'],
    marginBottom: '15px',
  },
  headerBottomTitle: {
    fontSize: ['2.5rem', '3.3rem'],
  },
  contentTitle: {
    fontSize: '2.1rem',
  },
}

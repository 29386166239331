export default {
  mainContentLinksBox: {
    div: {
      alignItems: 'center',
      gap: '10px',
      display: 'flex',
      flexDirection: 'column',
      h4: {
        px: ['0px', '25px'],
      },
      button: { width: ['100px', '130px'] },
    },
  },
}

/* eslint-disable react/no-unknown-property */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Image } from 'theme-ui'
import { useRouter } from 'next/router'
import tracking from '/lib/tracking'
import {
  TRACKING_EVENT_NAME,
  TRACKING_ACTION,
  TRACKING_CATEGORY,
} from '/constants/siteTagging'
import Utils from '/utils'
import styles from './styles'
import redirectToPage from '/lib/redirect'

export const NavBar = ({ navItems }) => {
  const { sendCSMEvent, sendAdobeEvent } = tracking()
  const router = useRouter()
  const locale = router?.query?.locale
  const items = navItems?.menu?.links

  const logo = '/static/aia-tth-logo-white.png'

  const matchRoute = (link) => {
    const isHomeRoute = router.route === '/[locale]'
    if (link === '/') {
      if (isHomeRoute) return styles.active
      return
    }
    const isCurrentPath = router.route.includes(link)
    if (isCurrentPath) return styles.active
  }

  return (
    <nav sx={styles.container}>
      <Box sx={styles.logoContainer}>
        <Image src={logo} onClick={() => redirectToPage(router, locale, '/')} />
      </Box>
      <ul sx={{ ...styles.linksList, variant: `text.navBarlinksList` }}>
        {items
          ?.filter((i) => i.text !== '' && i.text !== undefined)
          .map((item, index) => (
            <li
              key={index}
              onClick={() => {
                const trackInfo = Utils.getTrackingInfo(item.link)
                sendCSMEvent(
                  trackInfo.eventName,
                  TRACKING_ACTION.clicked_cta,
                  TRACKING_CATEGORY.menu_bar,
                  trackInfo.eventAttribute,
                  locale,
                )
                sendAdobeEvent(TRACKING_EVENT_NAME.navigation_click, {
                  navigationLinkNameClicked: trackInfo.eventAttribute,
                  navigationLocation: `${locale}${item.link}`,
                  language: locale,
                  websiteCountry: locale,
                })
                redirectToPage(router, locale, item.link)
              }}
            >
              {item.text}
              <Box
                sx={
                  matchRoute(item.link)
                    ? { ...styles.bar, ...styles.active }
                    : styles.bar
                }
              />
            </li>
          ))}
      </ul>
    </nav>
  )
}

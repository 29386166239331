export default {
  container: {
    position: 'absolute',
    right: ['0px', '12%'],
    top: ['40px', '30px'],
    backgroundColor: ['rgba(15, 32, 75, 0.8)', 'tmBlue'],
    zIndex: 3,
    color: 'tmWhite',
    borderBottomLeftRadius: '10px',
    padding: ['5px 25px 10px 10px', '2px 0px'],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: ['center'],
    justifyContent: ['center', 'flex-start'],
    gap: ['15px', '10px'],
  },
  arrowContainer: {
    width: '10px',
    height: '20px',
  },
  localeContainer: {
    width: '100%',
    minWidth: ['65px', '70px'],
    maxWidth: ['65px', '70px'],
    minHeight: ['auto', '40px'],
    display: 'flex',
    flexDirection: ['column', 'row'],
    alignItems: ['flex-start', 'flex-start'],
    order: [0, 1],
    gap: '3px',

    h4: {
      order: [0, 1],
    },
  },
  localeTitle: {
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: '1.7rem',
    lineHeight: '16px',
  },
  chinesseFormat: {
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: '1.4rem',
    lineHeight: '16px',
  },
  localeOptionsContainer: {
    width: ['100%', '225px'],
    minHeight: ['auto', '40px'],
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: ['column', 'row'],
    justifyContent: ['center', 'flex-end'],
    flexWrap: ['nowrap', 'wrap'],
    margin: ['auto', 0],
    gap: ['10px', '10px'],

    'li: hover': { color: 'tmRed' },
  },
}

export default {
  headerTitle: {
    fontSize: ['1.3rem', '2.5rem'],
    marginBottom: '10px',
  },
  headerSubtitle: {
    fontSize: ['1.5rem', '3rem'],
  },
  headerTopTitle: {
    fontSize: ['2rem', '2.6rem'],
    marginBottom: '5px',
  },
  headerBottomTitle: {
    fontSize: ['2.2rem', '3.1rem'],
  },
  contentTitle: {
    fontSize: '1.6rem',
  },
  partnershipDescription: {
    fontSize: ['0.9rem', '1rem'],
    py: ['20px', '30px'],
  },
  stats: {
    title: {
      color: ['tmBlue', 'tmBlue', 'primary'],
      fontFamily: 'bold',
      fontSize: ['16px', '12px'],
    },
    value: {
      fontFamily: 'bold',
      fontSize: ['16px', '12px'],
      color: ['tmRed', 'tmRed', 'primary'],
      ml: ['6px', '10px', '0px'],
    },
  },
}

import getConfig from 'next/config'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

export const config = {
  ...publicRuntimeConfig,
  ...serverRuntimeConfig,
}

export const REVALIDATE_INTERVAL_SECONDS = 60

export default config

export default {
  container: {
    position: 'relative',
    backgroundColor: '#f7f7f7',
    padding: '0px',
    maxWidth: '100vw',
    mx: 'auto',
  },
  viewport: {
    width: '100%',
  },
  innerBox: {
    display: 'flex',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    KhtmlUserSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
  },

  imageContainer: {
    position: 'relative',
    minWidth: '100%',
    maxHeight: ['400px'],
    borderRadius: ['0px', '0px', '40px', '40px'],
    overflow: 'hidden',
  },

  title: {
    color: 'tmBlue',
    fontWeight: 900,
    fontSize: ['2rem', '3.5rem'],
    textAlign: 'center',
    px: '50px',
    margin: 0,
    lineHeight: '24px',
  },
  subTitle: {
    color: 'tmBlue',
    fontSize: ['3rem', '4.5rem'],
    textAlign: 'center',
    px: '50px',
    margin: '0',
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: ['37%', '45%'],
    zIndex: 1,
  },
  button: {
    color: 'tmWhite',
    fontWeight: 'bold',
    fontSize: '25px',
    backgroundColor: 'tmRed',
    borderRadius: '10px 10px 10px 0px',
    padding: '2px 20px',
    marginTop: '15px',
    cursor: 'pointer',
  },
  image: {
    position: 'absolute',
    display: 'block',
    top: '50%',
    left: '50%',
    width: 'auto',
    minHeight: '100%',
    minWidth: '100%',
    maxWidth: 'none',
    transform: 'translate(-50%, -50%)',
  },
  dotContainer: {
    position: 'absolute',
    top: ['75%', '80%'],
    display: 'flex',
    width: '100%',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

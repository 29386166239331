import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import API from '/repository/repoAPI'
import logger from '/infra/logger'

const bulkDownloadImages = (imageUrls, zipName) => {
  const zip = new JSZip()

  const downloadPromises = imageUrls.map(async (imageUrl, index) => {
    const fileName = `image_${index + 1}.jpg`
    const { data } = await API.getCampAlbumImage()({ query: { imageUrl } })
    zip.file(fileName, data)
  })

  Promise.all(downloadPromises)
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, zipName)
      })
    })
    .catch((error) => {
      logger.error('Error during bulk image download:', error)
    })
}

export default bulkDownloadImages

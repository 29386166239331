import * as Infra from '/infra'

export default {
  getGeoIP,
  sendAnalytics,
  sendTracking,
  trackFacebookConversion,
  postNickname,
  postPinCode,
  getInfluencers,
  postConsentList,
  updateUserConsents,
  unlinkFromKakao,
  getUserFromDB,
  postEventMaster,
  postEventForm,
  getUserParticipationFromDB,
  getAlbumImages,
  getUserHasParticipatedInPOTMKorea,
  postEnterToWinContact,
  postEnterToWinSurvey,
  getCampAlbumImage,
  postAskSpursContact,
  postVoting,
  postVotingKorea,
  postContact,
  postSurvey,
}

function unlinkFromKakao(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/unlinkfromkakao', params)
}
function sendAnalytics(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/analytics', params)
}

function sendTracking(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/tracking', params)
}

function trackFacebookConversion(domain = 'UI') {
  return (params) => Infra.API()(domain).post(`/analytics/facebook`, params)
}
function postNickname(domain = 'UI') {
  return (params) => Infra.API()(domain).post(`/user/update-profile`, params)
}
function getInfluencers(domain = 'UI') {
  return (params) => Infra.API()(domain).get('/influencers', params)
}
function postConsentList(domain = 'UI') {
  return (params) => Infra.API()(domain).post(`/user/consent-details`, params)
}
function updateUserConsents(domain = 'UI') {
  return (params) => Infra.API()(domain).post(`/user/consents-update`, params)
}
function getUserFromDB(domain = 'UI') {
  return (params) => Infra.API()(domain).get('/user/fetch-user', params)
}
function postEventMaster(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/forms/event-master', params)
}
function postEventForm(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/forms/event-submit', params)
}
function getUserParticipationFromDB(domain = 'UI') {
  return (params) =>
    Infra.API()(domain).get('/user/fetch-participation', params)
}

function getUserHasParticipatedInPOTMKorea(domain = 'UI') {
  return (params) =>
    Infra.API()(domain).get('/user/korea-potm-participation', params)
}

function postPinCode(domain = 'UI') {
  //TODO: remove the hardcoded param (1)
  return (params) => Infra.API()(domain).post('/campalbum/pincode/1', params)
}

function getAlbumImages(albumId, domain = 'UI') {
  return (params) => Infra.API()(domain).post(`/campalbum/${albumId}`, params)
}

function getGeoIP(domain = 'UI') {
  return (params) =>
    domain === 'UI'
      ? Infra.API('')(domain).get('/geoip', params)
      : Infra.API('geo-ip')(domain).get('/', params)
}

function postEnterToWinContact(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/enterToWin/contact', params)
}

function postEnterToWinSurvey(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/enterToWin/survey', params)
}

function getCampAlbumImage(domain = 'UI') {
  return (params) =>
    Infra.API()(domain).get('/campalbum/images', {
      responseType: 'blob',
      timeout: 30000,
      ...params,
    })
}

function postAskSpursContact(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/askSpurs/contact', params)
}

function postVoting(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/voting', params)
}

function postVotingKorea(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/votingkr', params)
}

function postContact(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/contact', params)
}

function postSurvey(domain = 'UI') {
  return (params) => Infra.API()(domain).post('/survey', params)
}

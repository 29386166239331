import { rest } from 'msw'

const sampleGETRes = require('./fixtures/sample/get.json')
const geoIPGETRes = require('./fixtures/geoip/get.json')
const layoutGETRes = require('./fixtures/layout/get.json')
const formsGETRes = require('./fixtures/forms/get.json')
const contactGETRes = require('./fixtures/contact/post.json')

export const handlers = [
  rest.get(
    'http://INSERT_API_DOMAIN.green.room/api/v1/sample',
    (req, res, ctx) => {
      return res(ctx.json(sampleGETRes))
    },
  ),
  rest.get('/api/sample', (req, res, ctx) => {
    return res(ctx.json(sampleGETRes))
  }),

  rest.get('http://geo-ip.green.room/api/v1/country/', (req, res, ctx) => {
    return res(ctx.json(geoIPGETRes))
  }),

  rest.get('/api/layout', (req, res, ctx) => {
    return res(ctx.json(layoutGETRes))
  }),

  rest.get(
    'http://INSERT_API_DOMAIN.green.room/api/v1/layout',
    (req, res, ctx) => {
      return res(ctx.json(layoutGETRes))
    },
  ),

  rest.get('/api/forms', (req, res, ctx) => {
    return res(ctx.json(formsGETRes))
  }),
  rest.get(
    'http://INSERT_API_DOMAIN.green.room/api/v1/forms',
    (req, res, ctx) => {
      return res(ctx.json(formsGETRes))
    },
  ),

  rest.post('/api/contact', (req, res, ctx) => {
    return res(ctx.json(contactGETRes))
  }),
  rest.post(
    'http://INSERT_API_DOMAIN.green.room/api/v1/contact',
    (req, res, ctx) => {
      return res(ctx.json(contactGETRes))
    },
  ),

  rest.post('/api/survey', (req, res, ctx) => {
    return res(ctx.json({}))
  }),
  rest.post(
    'http://INSERT_API_DOMAIN.green.room/api/v1/survey',
    (req, res, ctx) => {
      return res(ctx.json({}))
    },
  ),

  rest.post('https://telemetry.nextjs.org/api/v1/record', (req, res, ctx) => {
    return res(ctx.json({}))
  }),
]

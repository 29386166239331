import React from 'react'

export const AdobeLaunch = ({ tag }) => {
  return (
    <script
      src={`https://assets.adobedtm.com/e17df9099c11/2f646724ad52/launch-${tag}.min.js`}
      async
    ></script>
  )
}

import React from 'react'
import { Global, css } from '@emotion/react'

const defaultFonts = css`
  @font-face {
    font-family: 'Gotham-Light';
    font-display: swap;
    src: url('/static/assets/fonts/Gotham-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'AIA-Body-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/AIA-Body-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'AIA-Body-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/AIA-Body-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'AIA-Heading-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/AIA-Heading-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'AIAEverest-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/AIAEverest-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'AIAEverest-Condensed';
    font-display: swap;
    src: url('/static/assets/fonts/AIAEverest-Condensed.woff') format('woff');
  }
  @font-face {
    font-family: 'AIAEverest-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/AIAEverest-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'SpoqaHanSansNeo-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/SpoqaHanSansNeo-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'SpoqaHanSansNeo-Thin';
    font-display: swap;
    src: url('/static/assets/fonts/SpoqaHanSansNeo-Thin.woff') format('woff');
  }
  @font-face {
    font-family: 'SpoqaHanSansNeo-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/SpoqaHanSansNeo-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'SpoqaHanSansNeo-Light';
    font-display: swap;
    src: url('/static/assets/fonts/SpoqaHanSansNeo-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'SpoqaHanSansNeo-Medium';
    font-display: swap;
    src: url('/static/assets/fonts/SpoqaHanSansNeo-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Hanuman-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/Hanuman-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'Hanuman-Regular';
    font-display: swap;
    src: url('/static/assets/fonts/Hanuman-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'MyanmarMN';
    font-display: swap;
    src: url('/static/assets/fonts/MyanmarMN.woff') format('woff');
  }
  @font-face {
    font-family: 'MyanmarMN-Bold';
    font-display: swap;
    src: url('/static/assets/fonts/MyanmarMN-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'sfufuturabold';
    font-display: swap;
    src: url('/static/assets/fonts/sfufuturabold.woff') format('woff');
  }
  @font-face {
    font-family: 'sfufuturalight';
    font-display: swap;
    src: url('/static/assets/fonts/sfufuturalight.woff') format('woff');
  }
  @font-face {
    font-family: 'sfufuturaregular';
    font-display: swap;
    src: url('/static/assets/fonts/sfufuturaregular.woff') format('woff');
  }

  body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    touch-action: none;
    --ms-touch-action: none;
  }
`

const GlobalComponent = () => <Global styles={defaultFonts} />

export default GlobalComponent
